<template>
  <section>
    <section class="top">
      <div>
        <p class="att_title">
          Attendance
        </p>
        <p class="att_subtitle">
          Logging For Class {{ this.$route.query.class }}
        </p>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          @click="log()"
        >
          Log It
        </VBtn>
      </div>
    </section>
    <section
      v-if="loadingPage ==='loaded'"
      id="listView"
      class="content"
    >
      <VListTile>
        <VListTileContent class="txt_bold">
          Student Name
        </VListTileContent>
        <VListTileAction class="txt_bold">
          Present / Absent
        </VListTileAction>
      </VListTile>
      <VDivider />
      <VForm
        ref="form"
        v-model="valid"
      >
        <VList
          two-line
          class="transparent"
        >
          <div
            v-for="(std, i) in newstd"
            :key="i"
          >
            <VListTile>
              <VListTileContent class="txt_bold">
                <VListTileTitle>
                  {{ std.name }}
                </VListTileTitle>
                <VListTileSubTitle>
                  {{ std.admNo }}
                </VListTileSubTitle>
              </VListTileContent>
              <VListTileAction>
                <VRadioGroup
                  v-model="newstd[i].status"
                  :rules="rule"
                  row
                >
                  <VRadio
                    value="present"
                    color="radio"
                    label="P"
                    class="mr-4 txt_bold"
                  />
                  <VRadio
                    value="absent"
                    color="radioRed"
                    label="A"
                    class="txt_bold"
                  />
                </VRadioGroup>
              </VListTileAction>
            </VListTile>
          </div>
        </VList>
      </VForm>
    </section>
    <section v-if="loadingPage ==='loading'">
      <div id="noData">
        <div>
          <div class="mb-4">
            <VProgressCircular
              indeterminate
              color="primary"
              :width="3"
              :size="40"
            />
          </div>
          Loading Data
        </div>
      </div>
    </section>
    <VSnackbar
      v-model="snackbar"
      color="secondary"
      :timeout="5000"
      :multi-line="true"
    >
      {{ snackText }}
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snackbar = false"
      >
        Close
      </VBtn>
    </VSnackbar>
    <VDialog
      v-model="isLoading"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Saving Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import api from '../../conf/api';

export default {
  data: () => ({
    valid: false,
    rule: [
      v => v !== '' || 'Please Mark The Attendance',
    ],
    students: [],
    newstd: [],
    isLoading: false,
    loadStatus: '',
    snackbar: false,
    snackText: '',
    loadingPage: '',
    retry: 0,
  }),
  async beforeMount() {
    if (this.$route.query.class !== '') {
      this.loadingPage = 'loading';
      await axios.get(`${api}/student/class/${this.$route.query.class}`)
        .then(((res) => {
          this.students = res.data.data;
          this.loadingPage = 'loaded';
        }));
      this.students.forEach((std) => {
        this.newstd.push(
          {
            name: std.name,
            admNo: std.admNo,
            class: std.class,
            status: '',
            date: moment().format('DD-MMMM-YYYY'),
            month: moment().format('MMMM'),
            year: moment().format('YYYY'),
          },
        );
      });
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    log() {
      const stdData = this.newstd;
      if (this.$refs.form.validate()) {
        this.loadStatus = 'loading';
        this.isLoading = true;
        axios.post(`${api}/attendance/add`, stdData).then((res) => {
          this.isLoading = false;
          if (res.data.status === 'success') {
            this.snackbar = true;
            this.snackText = res.data.message;
            setTimeout(() => {
              this.$router.push(`/attendance/${this.$route.query.class}`);
            }, 1000);
          }
          if (res.data.status === 'failed') {
            this.snackbar = true;
            this.snackText = res.data.message;
          }
        })
          .catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              this.retry = 10;
              setInterval(() => {
                this.retry -= 1;
              }, 1000);
              setTimeout(() => {
                window.location.reload();
              }, 9500);
            } else {
              this.loadStatus = 'fail';
            }
          });
      } else {
        this.snackbar = true;
        this.snackText = 'Please Mark All The Students';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Attendance/new';
</style>
